<template>
<div class="mt-2 flex flex-col">
    <div class="overflow-x-auto">
        <div class="inline-block min-w-full align-middle">
            <div class="overflow-hidden shadow border rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
                <thead class="bg-gray-50">
                    <tr class="select-none">
                        <th scope="col" class="py-3.5 pl-4 pr-3 sm:pl-6 text-left text-xs font-semibold text-gray-600 uppercase">Kraj</th>
                        <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Status</th>
                        <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Trasa</th>
                        <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Data wyjazdu</th>
                        <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Data powrotu</th>
                        <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Zamówienia</th>
                        <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Wartość trasy</th>
                        <th v-if="manageRoute" scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                            <span class="sr-only">Edit</span>
                        </th>
                    </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                    <tr v-for="route in sortedRoutes" :key="route.id">
                        <td class="whitespace-nowrap px-3 py-4 pl-4 sm:pl-6 text-sm font-medium text-gray-900">
                            <div class="inline-flex">
                                <div v-if="route.country !== undefined">
                                    <img v-if="route.country.flagUrl.length>0" :src="route.country.flagUrl" v-tooltip="`${route.country.displayName}`" class="h-4 w-6 mr-1 border border-gray-300">
                                    <span v-if="route.country.flagUrl.length === 0">---</span>
                                </div>
                                <div v-else>
                                    <span>---</span>
                                </div>
                            </div>
                        </td>
                        <td class="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900">
                            <div class="inline-flex">
                                <span v-tooltip="`Status: ${findStatus(route.statusId)?.name} - Informacje: ${findStatus(route.statusId)?.info}`" :class="[route.statusId === 1 ? 'bg-yellow-100 text-yellow-800' : '', route.statusId === 2 ? 'bg-blue-100 text-blue-800' : '', route.statusId === 2 ? 'bg-green-100 text-green-800' : '', 'rounded-md px-2.5 py-0.5 bg-gray-100 text-gray-800 text-xs font-medium']">
                                    {{ findStatus(route.statusId)?.name }}
                                </span>
                            </div>
                        </td>
                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            <div class="flex gap-1.5 cursor-default">
                                <div v-auto-animate class="whitespace-nowrap flex items-center justify-center">
                                    <UsersIcon v-tooltip="displayDrivers(route.drivers)" v-if="route.drivers.length > 1" class="w-5 h-5 text-green-500 transition-all duration-300" />
                                    <UserIcon v-tooltip="displayDrivers(route.drivers)" v-if="route.drivers.length === 1" class="w-5 h-5 text-green-500 transition-all duration-300" />
                                    <UsersIcon v-tooltip="displayDrivers(route.drivers)" v-if="route.drivers.length === 0" class="w-5 h-5 text-red-400 transition-all duration-300" />
                                </div>
                                <TruckIcon class="w-6 h-6 text-gray-400 hover:text-gray-500 transition-all duration-300" v-tooltip="`Tablica rejestracyjna: ${route.vehicle.attribs.licensePlate.value} - Przestrzeń transportowa: ${route.vehicle.attribs.payloadCapacity.value} m3`" />
                                <div class="inline-flex gap-2 items-center w-48">
                                    <span v-tooltip="`${route.title !== undefined && route.title.length > 0  ? route.title : (route.vehicle.brand + ' ' + route.vehicle.model + ' - ' + route.vehicle.attribs.licensePlate.value)}`" class="truncate font-semibold">{{route.title !== undefined && route.title.length > 0  ? route.title : (route.vehicle.brand + ' ' + route.vehicle.model + ' - ' + route.vehicle.attribs.licensePlate.value)}}</span>
                                </div>
                            </div>
                        </td>
                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <span class="text-xs">{{ moment.unix(route.date.start.seconds || route.date.start._seconds).format("LL") }}</span>
                        </td>
                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <span class="text-xs">{{ moment.unix(route.date.end.seconds || route.date.end._seconds).format("LL") }}</span>
                        </td>
                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex gap-2">
                            <div v-tooltip="route.allMessagesSent !== undefined && route.allMessagesSent ? 'Dostarczono do wszystkich wiadomości z potwierdzeniem' : 'Nie wysłano wszystkich wiadomości z potwierdzeniem'" :class="[route.allMessagesSent !== undefined && route.allMessagesSent ? 'bg-green-100 text-green-500' : 'bg-red-100 text-red-500', 'rounded-md p-1']">
                                <MailIcon class="h-4 w-4" />
                            </div>
                            <span @click="ordersModal.orders = route.orders, trackRouteDoc(route), selectedRoute = route ,ordersModal.open = true" class="transition ease-in-out duration-300 select-none w-fit inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-gray-100 text-gray-600 cursor-pointer hover:bg-blue-100 hover:text-blue-400">{{route.orders.length}}</span>
                        </td>
                        <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                            <div class="inline-flex gap-1 items-center rounded-full py-0.5 text-xs ">
                                <span :class="[getValues(route).length === 1 ? 'cursor-pointer' : 'cursor-default']" v-tooltip.top-start="getValues(route).length === 1 && `Kwota dostawy: ${createDeliveryPrice(route)}`"  v-for="manu in getValues(route) !== undefined ? getValues(route).slice(0,1) : ''" :key="manu">
                                    {{typeof manu === 'object' ? manu.name : manu}}
                                </span>
                                <span :class="[getValues(route) !== undefined ? 'cursor-pointer' : '', 'font-semibold']" v-tooltip="`${getValues(route) !== undefined ? getValues(route).slice(1).toString().replaceAll(',',' | ') : getValues(route)} + Kwota dostawy (${createDeliveryPrice(route)})`" v-if="getValues(route) !== undefined ? getValues(route).length - 1 > 0 : ''">
                                    + {{getValues(route) !== undefined ? getValues(route).length - 1 : ''}} więcej
                                </span>
                            </div>
                        </td>
                        <td v-if="manageRoute" class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <div class="flex items-center">
                                <div class="pr-2">
                                    <span v-tooltip="`Zarządzaj informacjami dla trasy - ${route.title !== undefined ? route.title : route.vehicle.brand + ' ' + route.vehicle.model}`" v-if="$route.path !== '/dashboard/routes/archives'" @click="selectedRoute = route, trackRouteDoc(route), modal.open = true" class="transition-all duration-300 text-indigo-500 hover:text-indigo-400 cursor-pointer select-none">
                                        Zarządzaj
                                        <span class="sr-only">, {{ route.id }}</span>
                                    </span>
                                </div>
                                <div class="border-l pl-2">
                                    <router-link v-tooltip="`Podgląd dla trasy ${route.title !== undefined ? route.title : route.vehicle.brand + ' ' + route.vehicle.model}`" :to="`/dashboard/routes/lookup?edit=${route.id}`" target="_blank" class="transition-all duration-300 text-indigo-600 hover:text-indigo-900">
                                    <DocumentSearchIcon class="transtion-all duration-300 h-5 w-5 text-gray-400 cursor-pointer hover:text-indigo-400" />
                                        <span class="sr-only">, {{ route.id }}</span>
                                    </router-link>
                                </div>
                                <DocumentDownloadIcon @click="generateCSV(route)" v-tooltip="`Pobierz plik do EXCEL dla trasy ${route.title !== undefined ? route.title : route.vehicle.brand + ' ' + route.vehicle.model}`" class="transtion ease-in-out duration-300 h-5 w-5 text-gray-400 cursor-pointer hover:text-blue-400" aria-hidden="true" />
                            </div>
                            <!-- <div @click.prevent="inRoute(route)">Test</div> -->
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>
        </div>
    </div>
</div>
<ManageRoutesModal v-if="modal.open" :route="selectedRoute" :statuses="statuses" @close="modal.open = false" @manageOrders="openOrdersModal" :blockModal="blockModal" @refreshModalData="refreshModalData"></ManageRoutesModal>
<OrdersModal v-if="ordersModal.open" :route="selectedRoute" @close="ordersModal.open = false" @manageRoute="openManageModal" :blockModal="blockModal" :sendingMessages="sendingMessages" @refreshModalData="refreshModalData"></OrdersModal>
</template>

<script>
import {db} from "@/firebase/gfbconf.js";
import moment from 'moment'
import ManageRoutesModal from '../components/ManageRoutesModal.vue'
import OrdersModal from '../components/OrdersModal.vue'
import { DocumentDownloadIcon, DocumentSearchIcon, TruckIcon, MailIcon, UsersIcon, UserIcon } from '@heroicons/vue/outline';
import currency from 'currency.js';

export default {
    components: { 
        TruckIcon, ManageRoutesModal, OrdersModal, DocumentDownloadIcon, DocumentSearchIcon, MailIcon, UsersIcon, UserIcon
    },
    props: {
        routes: Array,
        manageRoute: {
            type: Boolean,
            default: true
        },
        manageOrders: {
            type: Boolean,
            default: true
        }
    },
    emits: ["resetSearcher"],
    data() {
        return {
            moment: moment,
            statuses: [],
            selectedRoute: null,
            newRouteData: null,
            modal: {
                open: false,
            },
            ordersModal:{
                open: false,
                orders: null,
            },
            blockModal: {
                state: false,
                updatedBy: ""
            },
            orderStatuses: [],
            dbListener: null,
            sendingMessages: false,
        }
    },
    beforeUnmount() {
        if(this.dbListener !== null){
            this.dbListener();
        }
    },
    computed: {
        sortedRoutes() {
            return this.routes.slice().sort((a, b) => {
                // Function to extract a number from titles starting with 'Trasa'
                const extractNumber = title => {
                if (typeof title !== 'string') {
                    return null;
                }
                const match = title.match(/^Trasa (\d+)/);
                return match ? parseInt(match[1], 10) : null;
                };

                // Extract numbers for comparison
                const numberA = extractNumber(a.title);
                const numberB = extractNumber(b.title);

                // Prioritize titles with 'Trasa' and a number
                if (numberA !== null && numberB !== null) {
                return numberA - numberB;
                } else if (numberA !== null) {
                return -1; // Titles with 'Trasa' come first
                } else if (numberB !== null) {
                return 1; // Titles with 'Trasa' come first
                }

                // Fallback to alphabetical sorting or other logic for titles without 'Trasa'
                return a.title.localeCompare(b.title);
            });
        }
    },
    methods: {
        displayDrivers(drivers) {
            let driversList = 'Brak kierowców'
            if(drivers.length > 0) driversList = ''
            for(let i = 0; i < drivers.length; i++) {
                driversList += `${drivers[i].name} ${drivers[i].surname}${i < drivers.length - 1 ? ', ' : ''} `
            }
            return driversList
        },
        // async findAndUpdateOrdersWithoutRoute(route) {
        //     let testArray = [];
        //     console.log(route);
        //     for(let i=0; i<route.orders.length; i++)
        //     {
        //         testArray.push((await route.orders[i].ref.get()).data())
        //     }

        //     for(let y=0; y<testArray.length; y++)
        //     {
        //         console.log(testArray[y].statusId + ' ' + testArray[y].id);
        //         console.log(testArray[y].packages);
        //     }
            // let orders = await db.collection("Orders").where('statusId', '==', 4).get()
            // let routes = await db.collection("Routes").where('statusId', '==', 2).get()
            
            // let routeData = []
            // routes.docs.forEach(route => {
            //     routeData.push(route.data())
            // })

            // orders.forEach(order => {
            //     let found = false
            //     routeData.forEach(route => {
            //         route.orders.forEach(routeOrder => {
            //             if(routeOrder.ref.id === order.id) {
            //                 found = true
            //             }
                        
            //         })
            //     })
            //     if(!found) {
            //         // order.ref.update({statusId: 3})
            //         console.log(order.data());
            //     }
            // })
        // },
        // async inRoute(route)
        // {
        //     let arrayOfProductIds = [];
        //     for(let y=0; y<route.orders.length; y++)
        //     {
        //         let order = (await route.orders[y].ref.get()).data()
        //         if(order !== undefined)
        //         {
        //             console.log(order);
        //             const products = Object.entries(order.positions);
        //             for(let i=0; i<products.length; i++)
        //             {
        //                 arrayOfProductIds.push(products[i][1].product.id)
        //                 // console.log(products[i][1].product.id);
        //             }
        //             // arrayOfProductIds.push(order.id);
        //             // await db.collection('Orders').doc(order.id).update({
        //             //     "ordersIds": 3
        //             // })
        //             // if(order.statusId === 4)
        //             // {
        //             //     if(order.recentStatusId !== undefined)
        //             //     {
        //             //         console.log('jest' + ' ' + order.id);
        //             //         // await db.collection('Orders').doc(order.id).update({"statusId": order.recentStatusId})
        //             //     }
        //             //     else
        //             //     {
        //             //         console.log('nie ma');
        //             //         // await db.collection('Orders').doc(order.id).update({
        //             //         //     "statusId": 3
        //             //         // })
        //             //     }
        //             //     // console.log(await this.createProductsLine(order));
        //             //     // console.log(`${order.client.name} (tel. ${order.client.phone.full !== undefined && order.client.phone.full.length > 0 ? order.client.phone.full : '???'})`);
        //             //     // console.log('ID zamówienia w systemie: ' + ' ' + order.id);
        //             //     // console.log('ID zamówienia poza system' + ' ' + `${order.origin !== undefined ? order.origin.meta !== undefined ? order.origin.meta.external !== undefined ? order.origin.meta.external.ids !== undefined ? order.origin.meta.external.ids.externalOrderId !== undefined && order.origin.meta.external.ids.externalOrderId.length > 0 ? order.origin.meta.external.ids.externalOrderId : order.origin.meta.external.ids.bolOrderId !== undefined && order.origin.meta.external.ids.bolOrderId.length > 0 ? order.origin.meta.external.ids.bolOrderId : order.origin.meta.external.ids.hoodOrderId !== undefined && order.origin.meta.external.ids.hoodOrderId.length > 0 ? order.origin.meta.external.ids.hoodOrderId : "--" : "--" : "--" : "--" : "--"} ${order.origin !== undefined ? order.origin.meta !== undefined ? order.origin.meta.external !== undefined ? order.origin.meta.external.source !== undefined ? order.origin.meta.external.source.name !== undefined && order.origin.meta.external.source.name.length > 0 ? "(" + order.origin.meta.external.source.name + ")" : "(" + order.originName + ")" : "(" + order.originName + ")" : "(" + order.originName + ")" : "(" + order.originName + ")" : "(" + order.originName + ")"}`)
        //             // }
        //             // else
        //             // {
        //             //     console.log(order.statusId);
        //             // }
        //         }
        //         else
        //         {
        //             console.log(route.orders[y].ref.id);
        //         }
        //     }
        //         let uniqueProducts = arrayOfProductIds.filter((element, index) => {
        //             return arrayOfProductIds.indexOf(element) === index;
        //         });
        //     await db.collection('Routes').doc(route.id).update({
        //         "productIds": uniqueProducts
        //     })
        // },
        printLabels()
        {
            var docDefinition = {
                pageSize: 'A6',
                pageMargins: [10, 35, 10, 0 ],
                content: [
                ]
            };
            for (let o=0; o<this.orders.length; o++) {
                let order = this.orders[o]
                let address = []
                if(order.delivery.address.length > 0){
                    address.push(order.delivery.address)
                }
                if(order.delivery.postCode.length > 0){
                    address.push(order.delivery.postCode)
                }
                if(order.delivery.city.length > 0){
                    address.push(order.delivery.city)
                }
                if(order.delivery.country.name.length > 0){
                    address.push(order.delivery.country.name)
                }
                moment.locale('pl');
                let date = moment().format('L LT')

                for(let i = 0; i < Object.keys(order.positions).length; i++) {
                    for(let y = 0; y < order.positions[i+1].product.amountOfPackages; y++) {
                        let template = [
                        {stack: [
                        {text: 'Zamówienie', fontSize: 14, bold:true, alignment: 'center'},
                        {text: `${order.id}`, fontSize: 12, bold:true, alignment: 'center'},
                        {text: '', margin: [0,4,0,4]},
                        {text: [{text: 'Produkt: ', bold:true},`${order.positions[i+1].id} z ${this.amountOfItemsForLabel(order.positions)}`], fontSize: 9, alignment: 'center'},
                        {text: '', margin: [0,9,0,9]},
                        {text: {text: 'Nazwa produktu: ', bold:true}, fontSize: 9, alignment: 'center'},
                        {text: '', margin: [0,1,0,1]},
                        {text: {text: `${order.positions[i+1].product.name.length > 0 ? order.positions[i+1].product.name.substring(0,40) + `${order.positions[i+1].product.name.length > 40 ? '...' : ''}` : '--'}`, bold:true}, fontSize: 10, alignment: 'center'},
                        {text: '', margin: [0,9,0,9]},
                        {text: 'Klient: ', bold:true, fontSize: 9, alignment: 'center'},
                        {text: '', margin: [0,1,0,1]},
                        {
                            table: {
                            widths: ['50%','50%'],
                            body: [
                                [
                                    {text: {text: 'Imię i nazwisko:', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                                    {text: {text: 'Firma: ', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                                ],
                                [
                                    {text: {text: `${order.client.name.length > 0 ? order.client.name.substring(0,25) + `${order.client.name.length > 25 ? '...' : ''}` : order.invoice.fullname.length > 0 ? order.invoice.fullname.substring(0,25) + `${order.invoice.fullname.length > 25 ? '...' : ''}` : 'Brak'}`}, fontSize: 7, alignment: 'center', border: [false, false, false, false]},
                                    {text: {text: `${order.client.company.length > 0 ? order.client.company.substring(0,25) + `${order.client.company.length > 25 ? '...' : ''}` : order.invoice.company.length > 0 ? order.invoice.company.substring(0,25) + `${order.invoice.company.length > 25 ? '...' : ''}` : order.delivery.company.length > 0 ? order.delivery.company.substring(0,25) + `${order.delivery.company.length > 25 ? '...' : ''}` : '--'}`}, fontSize: 7, alignment: 'center', border: [false, false, false, false]},
                                ]
                            ]}
                        },
                        {
                            table: {
                            widths: ['50%','50%'],
                            body: [
                                [
                                    {text: {text: 'E-mail:', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                                    {text: {text: 'Numer telefonu: ', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                                ],
                                [
                                    {text: {text: `${order.client.email.length > 0 ? order.client.email.substring(0,25) + `${order.client.email.length > 25 ? '...' : ''}` : 'Brak'}`}, fontSize: 7, alignment: 'center', border: [false, false, false, false]},
                                    {text: {text: `${order.client.phone.full.length > 0 ? order.client.phone.full : 'Brak'}`}, fontSize: 7, alignment: 'center', border: [false, false, false, false]},
                                ]
                            ]}
                        },
                        {text: '', margin: [0,9,0,9]},
                        {text: 'Dostawa: ', bold:true, fontSize: 9, alignment: 'center'},
                        {text: '', margin: [0,1,0,1]},
                        {text: {text: `${address.toString().substring(0,120)}${address.toString().length > 120 ? '...' : ''}`}, fontSize: 7, alignment: 'center', border: [false, false, false, false]},
                        {text: '', margin: [0,9,0,9]},
                        {
                            table: {
                            widths: ['50%','50%'],
                            body: [
                                [
                                    { qr: `${order.id}:${order.positions[i+1].id}_${y+1}`, fit: '115', version: 3,  alignment: 'center', border: [false, false, false, false]},
                                    {
                                        table: {
                                        widths: ['100%'],
                                        body: [
                                            [
                                                {text: {text: 'Numer paczki:', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                                            ],
                                            [
                                                {text: {text: `${y+1} z ${order.positions[i+1].product.amountOfPackages}`, bold: true}, fontSize: 16, alignment: 'center', border: [false, false, false, false]},
                                            ]
                                        ]}, border: [false, false, false, false], margin: [0,24,0,0]
                                    },
                                ]
                            ]}
                        },
                        {text: '', margin: [0,12,0,12]},
                        {text: [{text: 'Wygenerowano: '},`${date}`], fontSize: 7, alignment: 'right', pageBreak: `${parseFloat(order.positions[i+1].id) === parseFloat(this.amountOfItemsForLabel(order.positions)) && parseFloat(y+1) === parseFloat(order.positions[i+1].product.amountOfPackages) ? '' : 'after'}`},
                        ],
                        unbreakable: true
                        }]
                        docDefinition.content.push(...template)
                    }
                }
            }
            pdfMake.createPdf(docDefinition).download(`Etykiety_zamówień_${this.nameToDisplay}.pdf`);
        },
        refreshModalData() {
            this.selectedRoute = this.newRouteData
            this.blockModal.state = false
            this.blockModal.updateBy = ""
        },
        trackRouteDoc(route) {
            if(this.dbListener !== null){
                this.dbListener()
                this.dbListener = null
            }
            this.dbListener = db.collection("Routes").doc(route.id)
            .onSnapshot(async (querySnapshot) => {
                if(!querySnapshot.exists) {
                    this.blockModal.state = false
                    this.blockModal.updatedBy = ""
                    this.modal.open = false
                    this.ordersModal.open = false
                    this.ordersModal.orders = null
                    this.selectedRoute = null
                    this.newRouteData = null
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Akcja zakończona sukcesem!",
                        subheader: "Trasa została pomyślnie usunięta.",
                        success: true
                    });
                } else {
                    let newRouteData = querySnapshot.data() 
                    if(this.selectedRoute.meta.lastModificationDate.seconds !== newRouteData.meta.lastModificationDate.seconds) {
                        const vehicle = await newRouteData.vehicle.get()
                        newRouteData.vehicle = vehicle.data();
                        if(newRouteData.meta.name !== undefined) this.blockModal.updatedBy = newRouteData.meta.name
                        this.newRouteData = newRouteData
                        this.sendingMessages = newRouteData.sendingMessages
                        this.blockModal.state = true
                    } else if(this.selectedRoute.sendingMessages !== undefined && this.selectedRoute.sendingMessages !== newRouteData.sendingMessages) {
                        this.sendingMessages = newRouteData.sendingMessages
                    } else {
                        this.blockModal.state = false
                        this.blockModal.updatedBy = ""
                    }
                    

                }
            });
        },
        createDeliveryPrice(route)
        {
          let translatedListOfDelivery = [];
            if(route.value !== undefined)
            {
                if(route.value.delivery !== undefined)
                {
                    if(Object.keys(route.value.delivery).length > 0)
                    {
                        const values = Object.entries(route.value.delivery)
                        values.forEach(element => {
                            if(element[0])
                            {
                                translatedListOfDelivery.push(parseFloat(element[1].price).toFixed(2) + ' ' + element[0]);
                            }
                        })
                    }
                    else
                    {
                       translatedListOfDelivery.push('---'); 
                    }
                }
                else
                {
                    translatedListOfDelivery.push('---');
                }
            }
            else
            {
                translatedListOfDelivery.push('---');
            }
            return translatedListOfDelivery
        },
        createCommentList(order)
        {
            const products = Object.entries(order.positions)
            let translatedListOfComments = [];
            products.forEach(element => {
                if(element[0])
                {
                    translatedListOfComments.push(element[1].infos.onOrderCreation);
                }
            })

            return translatedListOfComments
        },
        getValues(route)
        {
            let translatedListOfValues = [];
            if(route.value !== undefined && route.value !== null)
            {
                if(route.value.total !== undefined && route.value.total !== null)
                {
                    const values = Object.entries(route.value.total)
                    values.forEach(element => {
                        if(values.length > 1)
                        {
                            if(element[0])
                            {
                                if(element[1].value > 0)
                                {
                                    let parsedValue = currency(element[1].value).value
                                    translatedListOfValues.push(`${parsedValue + ' ' + element[0]}`);
                                }
                            }
                        }
                        else
                        {
                            if(element[0])
                            {
                                if(element[1].value > 0)
                                {
                                    let parsedValue = currency(element[1].value).value
                                    translatedListOfValues.push(`${parsedValue + ' ' + element[0]}`);
                                }
                                else
                                {
                                    translatedListOfValues.push('---');
                                }
                            }
                        }
                    })
                }
                else
                {
                    translatedListOfValues.push('---');
                }
            }
            else
            {
                translatedListOfValues.push('---');
            }
            return translatedListOfValues
        },
        async generateCSV(route)
        {
            try {
                this.$store.commit('setGlobalLoader',{
                    show: true,
                    head: "Trwa generowanie pliku dla trasy " + (route.title !== undefined ? route.title : route.vehicle.brand + ' ' + route.vehicle.model),
                    subheader: `Nie zamykaj tego okna.`,
                });
                
                if(this.orderStatuses.length === 0){
                    const statuses = await db.collection("OrderStatuses").get()
                    statuses.forEach(statusesList => {
                    this.orderStatuses = statusesList.data().list
                    })
                }
                let routeHeaderLine = {
                    routeId: 'ID trasy:',
                    routeName: 'Nazwa trasy:',
                    routeDesc: 'Opis trasy:',
                    break1: ' ',
                    routeDrivers: 'Kierowcy:',
                    routeVehicle: 'Pojazd:',
                    break2: ' ',
                    routeDate: 'Data trasy:',
                    break3: ' ',
                    routeValue: 'Wartość trasy:',
                    break4: ' '
                };
                let routeDataLine = {
                    routeId: `${route.id !== undefined && route.id.length > 0 ? route.id : '???'}`,
                    routeName: `${route.title !== undefined ? route.title : route.vehicle.brand + ' ' + route.vehicle.model}`,
                    routeDesc: `${route.description !== undefined && route.description.length > 0 ? route.description : '--'}`,
                    break1: ' ',
                    routeDrivers: `${route.drivers.length === 0 ? '--' : ''}`,
                    routeVehicle: `${route.vehicle.brand !== undefined && route.vehicle.brand.length > 0 ? route.vehicle.brand : '???'} ${route.vehicle.model !== undefined && route.vehicle.model.length > 0 ? route.vehicle.model : '???'} (rej. ${route.vehicle.attribs.licensePlate.value !== undefined && route.vehicle.attribs.licensePlate.value.length > 0 ? route.vehicle.attribs.licensePlate.value : '???'})`,
                    break2: ' ',
                    routeDate: `${moment.unix(route.date.start.seconds || route.date.start._seconds).format("LL")} - ${moment.unix(route.date.end.seconds || route.date.end._seconds).format("LL")}`,
                    break3: ' ',
                    // routeValue: `${route.value !== undefined ? (route.value.totalRouteValue !== undefined ? route.value.totalRouteValue.toFixed(2).toString() : '???') + ' ' + (route.value.currency !== undefined && route.value.currency.length > 0 ? route.value.currency : '???') : '???'}`
                    routeValue: `${this.getValues(route)}, Kwota dostawy: ${this.createDeliveryPrice(route)}`,
                    break4: ' ',
                }
                for (let d = 0; d < route.drivers.length; d++) {
                    let crntDriver = route.drivers[d]
                    routeDataLine.routeDrivers += `${d > 0 ? ', ' : ''}${crntDriver.name !== undefined && crntDriver.name.length > 0 ? crntDriver.name : '???'} ${crntDriver.surname !== undefined && crntDriver.surname.length > 0 ? crntDriver.surname : '???'} (tel: ${crntDriver.contact.phone !== undefined && crntDriver.contact.phone.length > 0 ? crntDriver.contact.phone : '???'})`
                }
                let breakLine = {}
    
                let routeOrdersHeaderLine = {
                    index: 'L.p.:',
                    address: 'Adres:',
                    products: 'Produkty:',
                    manufacturer: 'Producenci:',
                    client: 'Klient:',
                    internalOrderId: 'Wewnętrzne ID:',
                    externalOrderId: 'Zewnętrzne ID:',
                    confirmedDeliveryDate: 'Data oznaczenia jako dostarczone:',
                    plannedDeliveryDate: 'Planowana data dostawy:',
                    plannedDeliveryHours: 'Planowana godzina dostawy:',
                    actualStatus: 'Aktualny status zamówienia:',
                    orderValue: 'Wartość zamówienia:',
                    returnAmount: 'Kwota do zwrotu:',
                    noteInsideOrder: 'Komentarz wewnętrzny:'
                };
    
                const csvForRoute = [
                    routeHeaderLine,
                    routeDataLine,
                    breakLine,
                    breakLine,
                    routeOrdersHeaderLine,
                ];
    
                for (let o = 0; o < route.orders.length; o++)
                {
                    let crntOrderDoc = await route.orders[o].ref.get()
                    let crntOrder = crntOrderDoc.data()
                    let orderLine = {
                        index: `${o+1}`,
                        address: `${crntOrder.delivery.country.code}, ${crntOrder.delivery.postCode}, ${crntOrder.delivery.city}, ${crntOrder.delivery.address}`,
                        products: `${await this.createProductsLine(crntOrder)}`,
                        manufacturer: `${this.getManufacturer(crntOrder).toString().replaceAll(',',' | ')}`,
                        client: `${crntOrder.client.name} (tel. ${crntOrder.client.phone.full !== undefined && crntOrder.client.phone.full.length > 0 ? crntOrder.client.phone.full : '???'})`,
                        internalOrderId: `${crntOrder.id}`,
                        externalOrderId: `${crntOrder.originName === 'Manual' ? (crntOrder.meta.external != null && crntOrder.meta.external.ids != null) ? crntOrder.meta.external.ids.externalOrderId : '---' : crntOrder.origin !== undefined ? crntOrder.origin.meta !== undefined ? crntOrder.origin.meta.external !== undefined ? crntOrder.origin.meta.external.ids !== undefined ? crntOrder.origin.meta.external.ids.externalOrderId !== undefined && crntOrder.origin.meta.external.ids.externalOrderId.length > 0 ? crntOrder.origin.meta.external.ids.externalOrderId : crntOrder.origin.meta.external.ids.bolOrderId !== undefined && crntOrder.origin.meta.external.ids.bolOrderId.length > 0 ? crntOrder.origin.meta.external.ids.bolOrderId : crntOrder.origin.meta.external.ids.hoodOrderId !== undefined && crntOrder.origin.meta.external.ids.hoodOrderId.length > 0 ? crntOrder.origin.meta.external.ids.hoodOrderId : crntOrder.origin.meta.external.ids.apiloOrderId !== undefined && crntOrder.origin.meta.external.ids.apiloOrderId.length > 0 ? crntOrder.origin.meta.external.ids.apiloOrderId : crntOrder.origin.meta.external.ids.ottoOrderId !== undefined && crntOrder.origin.meta.external.ids.ottoOrderId.length > 0 ? crntOrder.origin.meta.external.ids.ottoOrderId : "--"  : "--" : "--" : "--" : "--"} ${crntOrder.origin !== undefined ? crntOrder.origin.meta !== undefined ? crntOrder.origin.meta.external !== undefined ? crntOrder.origin.meta.external.source !== undefined ? crntOrder.origin.meta.external.source.name !== undefined && crntOrder.origin.meta.external.source.name.length > 0 ? "(" + crntOrder.origin.meta.external.source.name + ")" : "(" + crntOrder.originName + ")" : "(" + crntOrder.originName + ")" : "(" + crntOrder.originName + ")" : "(" + crntOrder.originName + ")" : "(" + crntOrder.originName + ")"}`,
                        confirmedDeliveryDate: `${crntOrder.delivery.date.confirmed === null ? '--' : crntOrder.delivery.date.confirmed.asText !== undefined ? crntOrder.delivery.date.confirmed.asText : '--'}`,
                        plannedDeliveryDate: crntOrder.delivery.date.planned != null && crntOrder.delivery.date.planned.unix != null ? (moment.unix(crntOrder.delivery.date.planned.unix).format("DD.MM.YYYY")) : 'Brak ustalonej daty',
                        plannedDeliveryHours: crntOrder.delivery.date.planned != null && crntOrder.delivery.date.planned.toHourUnix != null && crntOrder.delivery.date.planned.fromHourUnix != null ? (moment.unix(crntOrder.delivery.date.planned.fromHourUnix).format('HH:mm') + ' - ' + moment.unix(crntOrder.delivery.date.planned.toHourUnix).format('HH:mm')) : '---',
                        actualStatus: `${this.findOrderStatus(crntOrder.statusId).name} ${crntOrder.recentStatusId !== undefined && (crntOrder.recentStatusId === 400 || crntOrder.recentStatusId === 401) ? "(" + this.findOrderStatus(crntOrder.recentStatusId).name + ")" : "" }`,
                        orderValue: `${this.countOrderValue(crntOrder)}`,
                        returnAmount: crntOrder.payment.return == null ? 0 : crntOrder.payment.return.amount,
                        noteInsideOrder: `${this.createCommentList(crntOrder)}`
                    }
                    csvForRoute.push(orderLine)
                }
                let routeName = `${route.title !== undefined ? route.title : route.vehicle.brand + ' ' + route.vehicle.model}`
                routeName = routeName.replaceAll(' ','-')
                let routeId = `${route.id !== undefined && route.id.length > 0 ? route.id : '???'}`
                const csvdata = this.csvmaker(csvForRoute);
                this.download(csvdata, routeName,routeId);
                this.$store.commit("resetGlobalLoader");
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Wygenerowano plik dla trasy " + routeName,
                    subheader: `Plik został pobrany na Twój komputer.`,
                    success: true
                });
            } catch (error)
            {
                console.log(error);    
                this.$store.commit("resetGlobalLoader");
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Wystąpił błąd podczas generowania pliku dla trasy",
                    subheader: `Spróbuj ponownie później.`,
                    success: true
                });
            }
        },
        csvmaker(data)
        {
            let csvRows = [];
            for(let i=0;i<data.length; i++)
            {
                const values = Object.values(data[i]).join(';');
                csvRows.push(values)
            }
            return csvRows.join('\n')
        },
        download(data, fileName)
        {
            const blob = new Blob(["\ufeff", data], { type: 'text/csv;charset=utf-8'});
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.setAttribute('href', url);
            a.setAttribute('download', `${fileName}`+`.csv`);
            a.click()
        },
        async createProductsLine(order)
        {
            let products = ""
            let variantName = ''
            for(const pos in order.positions)
            {
                let crntPos = order.positions[pos]
                let variantId = crntPos.product.variantId
                let product = (await crntPos.product.ref.get()).data()
                if(product !== undefined)
                {
                    products = products.concat(`${crntPos.product.quantity}x ${product.name} `)
                    if(product.variants[variantId] != null)
                    {
                        variantName += `(${product.variants[variantId].name}) `
                    }
                    else
                    {
                        variantName = `(${product.name} - USUNIĘTY WARIANT)`
                    }
                }
                else
                {
                    variantName = `${crntPos.product.quantity}x ${crntPos.product.name} `
                }
            }
            let productsString = `${products + variantName}`
            return productsString
        },
        getManufacturer(order)
        {
            const manufacturer = Object.entries(order.positions)
            let translatedListOfManufacturer = [];
            manufacturer.forEach(element => {
                if(element[0])
                {
                    if(!translatedListOfManufacturer.includes(element[1].product.manufacturer))
                    {
                        translatedListOfManufacturer.push(typeof element[1].product.manufacturer === 'object' ? element[1].product.manufacturer.name : element[1].product.manufacturer);
                    }
                }
            })
            return translatedListOfManufacturer
        },
        countOrderValue(order){
            let value = {
                totalOrderValue: 0,
                currency: '',
            };

            for(const pos in order.positions)
            {
                value = {
                    totalOrderValue: value.totalOrderValue + (order.positions[pos].product.quantity * order.positions[pos].product.pricePerItemTaxIncluded),
                    currency: order.positions[1].product.currency
                }
            }

            let valueString = `${value.totalOrderValue.toFixed(2)} ${value.currency}`
            return valueString
        },
        openOrdersModal(route)
        {
            this.modal.open = false
            this.selectedRoute = route
            this.ordersModal.orders = route.orders
            this.ordersModal.open = true
        },
        openManageModal(route)
        {
            this.ordersModal.open = false
            this.selectedRoute.orders = route
            this.modal.open = true
        },
        findStatus(statusId)
        {
            return this.statuses.find(el => el.id === statusId)
        },
        findOrderStatus(statusId)
        {
            return this.orderStatuses.find(el => el.id === statusId)
        },
        prepareElasticDocument(route)
        {
            let document = {
                // names: [route.group.name],
                // countries: [route.group.country.name, route.group.country.SelectInputDisplayName],
                brands: [route.vehicle.brand],
                models: [route.vehicle.model],
                licensePlates: [route.vehicle.attribs.licensePlate.value],
                id: route.id,
            }
            return document;
        },
    },
    async created()
    {
        moment.locale('pl');
        const statuses = await db.collection("RouteStatuses").get()
        statuses.forEach(statusesList => {
            this.statuses = statusesList.data().list
        })
    },
}
</script>